import React, { useState } from "react";

export const Tabs = ({ children }) => {
  const findActiveTab = (a) => {
    return a.reduce((accumulator, currentValue, i) => {
      if (currentValue.props.active) {
        return i;
      }
      return accumulator;
    }, 0);
  };

  const tabValidator = (tab) => {
    return tab.type.displayName === "Tab" ? true : false;
  };

  const saveActiveApp = (app) => {
    app.props.onActive(app.key);
  };

  const [activeTab, setActiveTab] = useState(findActiveTab(children));
  return (
    <>
      <div className="flex gap-2 justify-center bg-gray-100 rounded-lg p-2">
        {children.map((item, i) => {
          return (
            <div key={item.key}>
              {tabValidator(item) && (
                <Tab
                  currentTab={i}
                  activeTab={activeTab}
                  app={item}
                  setActiveTab={setActiveTab}
                  setActiveApp={saveActiveApp}
                >
                  {item.props.children}
                </Tab>
              )}
            </div>
          );
        })}
      </div>
      <div className="p-5">
        {children.map((item, i) => {
          return (
            <div
              key={item.key}
              className={` ${i === activeTab ? "visible" : "hidden"}`}
            >
              {item.props.component}
            </div>
          );
        })}
      </div>
    </>
  );
};

export const Tab = ({
  children,
  activeTab,
  app,
  currentTab,
  setActiveTab,
  setActiveApp,
}) => {
  const handleChangeActive = () => {
    setActiveTab(currentTab);
    setActiveApp(app);
  };
  return (
    <>
      <div
        className={`px-5 py-3 rounded cursor-pointer
      ${activeTab === currentTab ? "bg-white" : "bg-slate-100 text-slate-500"}`}
        onClick={handleChangeActive}
      >
        {children}
      </div>
    </>
  );
};

Tab.displayName = "Tab";
