import React from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Legend } from "recharts";

const tempData = [
  { name: "Paid", value: 52 },
  { name: "Organic", value: 44 },
];

export const HSDonutChart = ({
  data = tempData,
  dataKey = "value",
  nameKey = "name",
}) => {
  return (
    // TODO: ResponsiveContainer to be fixed
    // <ResponsiveContainer width="100%" height="100%">
    <PieChart width={300} height={400}>
      <Pie
        data={data}
        dataKey={dataKey}
        nameKey={nameKey}
        cx="50%"
        cy="50%"
        innerRadius={60}
        outerRadius={100}
        fill="#82ca9d"
        label
      />
      <Tooltip />
      <Legend />
    </PieChart>
    // </ResponsiveContainer>
  );
};
