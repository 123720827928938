import { FB } from "../services/facebook.service";
import _ from "lodash";

const useFacebook = () => {
  const fetchInsights = async (pageId, pageToken) => {
    try {
      // check login state
      let login = await FB.initialize();
      if (!login || !login?.user_token)
        return {
          error: {
            desc: "Authentication state has expired. Please authenticate",
          },
        };

      let { isSuccess, error, data } = await _getInsights(pageId, pageToken);
      let positiveFeedbacks = await _getPositiveFeedbackInsights(
        pageId,
        pageToken
      );

      // remove on publish
      // data = _mockData();
      // isSuccess = data?.length > 0;
      // error = data?.length > 0 ? null : error;

      if (isSuccess)
        return {
          isSuccess: isSuccess,
          data:
            data.length === 0 ? null : _formatedData(data, positiveFeedbacks),
          error: error,
        };

      // second attempt, get insights by regenerating new page token
      const { success, page_token } = await FB.getPageAccessToken(
        pageId,
        login.user_token
      );

      if (!success)
        return {
          isSuccess: false,
          error: { desc: "Could not get page access token." },
        };

      const result = await _getInsights(pageId, page_token);
      positiveFeedbacks = await _getPositiveFeedbackInsights(pageId, pageToken);

      if (result.isSuccess)
        return {
          isSuccess: isSuccess,
          data:
            data.length === 0 ? null : _formatedData(data, positiveFeedbacks),
          error: error,
        };

      return {
        isSuccess: true,
        error: result.error,
        newToken: page_token,
      };
    } catch (err) {
      return {
        isSuccess: false,
        error: { desc: err.response.data.error ?? "Unable to get insights." },
      };
    }
  };

  const fetchPages = async (userId, appId) => {
    try {
      let login = await FB.initialize();
      if (!login || !login?.user_token)
        return {
          error: {
            success: false,
            desc: "Failed to get Facebook pages. Authentication failed.",
          },
        };

      let res = await FB.getPages(login.user_id, login.user_token);
      if (!res.success || res.data?.length === 0)
        return {
          success: false,
          error: { desc: res.data.error ?? "Could not get Facebook pages." },
        };

      return {
        success: true,
        fbPages: res.data.map((page) => {
          return {
            user_id: userId,
            app_id: appId,
            account_id: login.user_id,
            user_token: login.user_token,
            page_id: page.id,
            page_name: page.name,
            page_token: page.access_token,
            page_picture: page.picture?.data?.url,
            default: false,
          };
        }),
      };
    } catch (err) {
      return {
        success: false,
        error: {
          desc: err.response.data.error ?? "Unable to get Facebook pages.",
        },
      };
    }
  };

  const fetchPostsData = async (pageId, pageToken) => {
    try {
      // check login state
      let login = await FB.initialize();
      if (!login || !login?.user_token)
        return {
          error: {
            desc: "Authentication state has expired. Please authenticate",
          },
        };

      const _getPostsTotalCount = async (page_id, page_token) => {
        const { success, error, data, summary } =
          await FB.getTotalNumberOfPosts(page_id, page_token);

        if (!success) return { isSuccess: false, error: error };

        return {
          isSuccessTotalPostsCount: true,
          dataTotalPosts: data,
          errorTotalPostsCount: {
            desc: data.length === 0 ? "There are no data for this page." : null,
          },
          totalCount: summary.total_count,
        };
      };

      const _getPostsTotalMonthlyCount = async (page_id, page_token) => {
        const { success, error, data, summary } =
          await FB.getTotalNumberOfPostsFromLastMonth(page_id, page_token);

        if (!success) return { isSuccess: false, error: error };

        return {
          isSuccess: true,
          data: data,
          error: {
            desc: data.length === 0 ? "There are no data for this page." : null,
          },
          summary: summary,
        };
      };

      const { isSuccess, summary } = await _getPostsTotalMonthlyCount(
        pageId,
        pageToken
      );

      const {
        isSuccessTotalPostsCount,
        dataTotalPosts,
        errorTotalPostsCount,
        totalCount,
      } = await _getPostsTotalCount(pageId, pageToken);

      const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const formatedDate =
          date.getUTCFullYear() +
          "/" +
          (date.getUTCMonth() + 1) +
          "/" +
          date.getUTCDate();
        return formatedDate;
      };

      const countBy = (arr, fn) =>
        Object.values(
          arr.map(typeof fn === "function" ? fn : (val) => val[fn]).reduce(
            (acc, value) =>
              acc[value]
                ? {
                    ...acc,
                    [value]: { name: value, count: acc[value].count + 1 },
                  }
                : { ...acc, [value]: { name: value, count: 1 } },
            {}
          )
        );

      const groupedByCountPosts = countBy(dataTotalPosts, (x) =>
        formatDate(x.created_time)
      );

      if (isSuccess && isSuccessTotalPostsCount) {
        return {
          isSuccess: true,
          data: {
            isSuccess: true,
            groupedByCountPosts,
            errorTotalPostsCount,
            monlthyTotalCount: summary.total_count,
            totalCount,
          },
          errorTotalPostsCount,
          monlthyTotalCount: summary.total_count,
          totalCount,
        };
      }

      // regenerate page token
      const { success, page_token } = await FB.getPageAccessToken(
        pageId,
        login.user_token
      );

      if (!success)
        return {
          isSuccess: false,
          error: { desc: "Could not get page access token." },
        };

      const totalPostsResult = await _getPostsTotalCount(pageId, page_token);
      const monlthyPostsResult = await _getPostsTotalMonthlyCount(
        pageId,
        page_token
      );

      if (monlthyPostsResult.isSuccess && monlthyPostsResult.isSuccess)
        return {
          isSuccess: true,
          data: {
            isSuccess: true,
            dataTotalPosts: totalPostsResult.data,
            error: totalPostsResult.error,
            monlthyTotalCount: summary.total_count,
            totalCount: totalPostsResult.summary.total_count,
          },
          error: totalPostsResult.error,
          monlthyTotalCount: monlthyPostsResult.summary.total_count,
          totalCount: totalPostsResult.summary.total_count,
        };

      return {
        isSuccess: true,
        error: monlthyPostsResult.error,
        newToken: page_token,
      };
    } catch (err) {
      return {
        isSuccess: false,
        error: {
          desc:
            err.response == null
              ? err.message
              : err.response.data.error ??
                "Unable to get total number of posts.",
        },
      };
    }
  };

  const fetchInsightsGraphData = async (pageId, pageToken) => {
    try {
      // check login state
      let login = await FB.initialize();
      if (!login || !login?.user_token)
        return {
          error: {
            desc: "Authentication state has expired. Please authenticate",
          },
        };

      const _getPageViewsFromTodaysDateForAWeekPerDay = async (
        page_id,
        page_token
      ) => {
        const { success, error, data } =
          await FB.getInsightsFromTodaysDateForAWeek(page_id, page_token);

        if (!success) return { isSuccess: false, error: error };

        return {
          isSuccessTotalForAWeekPerDay: true,
          dataTotalForAWeekPerDay: data,
          errorTotalForAWeekPerDay: {
            desc: data.length === 0 ? "There are no data for this page." : null,
          },
        };
      };

      const {
        isSuccessTotalForAWeekPerDay,
        dataTotalForAWeekPerDay,
        errorTotalForAWeekPerDay,
      } = await _getPageViewsFromTodaysDateForAWeekPerDay(pageId, pageToken);

      const formatedData = _formatedGraphData(dataTotalForAWeekPerDay);

      if (isSuccessTotalForAWeekPerDay) {
        return {
          isSuccess: true,
          data: {
            isSuccess: true,
            dataTotalForAWeekPerDay: formatedData,
          },
          error: errorTotalForAWeekPerDay,
        };
      }

      // regenerate page token
      const { success, page_token } = await FB.getPageAccessToken(
        pageId,
        login.user_token
      );

      if (!success)
        return {
          isSuccess: false,
          error: { desc: "Could not get page access token." },
        };

      const totalTotalForAWeekPerDay =
        await _getPageViewsFromTodaysDateForAWeekPerDay(pageId, pageToken);

      if (totalTotalForAWeekPerDay.isSuccessTotalForAWeekPerDay)
        return {
          isSuccess: true,
          data: {
            isSuccess: true,
            dataTotalPosts: _formatedGraphData(
              totalTotalForAWeekPerDay.dataTotalForAWeekPerDay
            ),
          },
          error: totalTotalForAWeekPerDay.error,
        };

      return {
        isSuccess: true,
        error: totalTotalForAWeekPerDay.error,
        newToken: page_token,
      };
    } catch (err) {
      return {
        isSuccess: false,
        error: {
          desc:
            err.response == null
              ? err.message
              : err.response.data.error ??
                "Unable to get total number of posts.",
        },
      };
    }
  };

  const fetchFollowersAndFanCount = async (pageId, pageToken) => {
    try {
      // check login state
      let login = await FB.initialize();
      if (!login || !login?.user_token)
        return {
          error: {
            desc: "Authentication state has expired. Please authenticate",
          },
        };

      const _getFollowrsAndFanCount = async (page_id, page_token) => {
        const { success, error, data } = await FB.getFollowersAndFanCount(
          page_id,
          page_token
        );

        if (!success) return { isSuccess: false, error: error };

        return {
          isSuccess: true,
          data: data,
          error: {
            desc: data.length === 0 ? "There are no data for this page." : null,
          },
        };
      };

      const { isSuccess, error, data } = await _getFollowrsAndFanCount(
        pageId,
        pageToken
      );

      if (isSuccess) {
        return {
          isSuccess: true,
          data: {
            isSuccess: true,
            data,
            error,
          },
          error,
        };
      }

      // regenerate page token
      const { success, page_token } = await FB.getPageAccessToken(
        pageId,
        login.user_token
      );

      if (!success)
        return {
          isSuccess: false,
          error: { desc: "Could not get page access token." },
        };

      const result = await _getFollowrsAndFanCount(pageId, page_token);

      if (result.isSuccess)
        return {
          isSuccess: true,
          data: {
            isSuccess: true,
            data: result.data,
            error: result.error,
          },
          error: result.error,
        };

      return {
        isSuccess: true,
        error: result.error,
        newToken: page_token,
      };
    } catch (err) {
      return {
        isSuccess: false,
        error: {
          desc:
            err.response.data.error ?? "Unable to get total number of posts.",
        },
      };
    }
  };

  return {
    fetchInsights,
    fetchPages,
    fetchPostsData,
    fetchFollowersAndFanCount,
    fetchInsightsGraphData,
  };
};

const _getInsights = async (page_id, page_token) => {
  const { success, error, data } = await FB.getInsights(page_id, page_token);

  if (!success) return { isSuccess: false, error: error };

  return {
    isSuccess: true,
    data: data,
    error: {
      desc: data.length === 0 ? "There are no insights for this page." : null,
    },
  };
};

const _getPositiveFeedbackInsights = async (page_id, page_token) => {
  const { data } = await FB.getPositiveFeedbackInsights(page_id, page_token);
  return data;
};

const _formatedData = (data, positiveFeedbacks) => {
  let _obj = {};
  _.chain(data)
    .groupBy("name")
    .map((value, key) => {
      let values = {};
      value.map((itm, i) =>
        Object.assign(values, {
          [itm.period]: itm.values[itm.values.length - 1].value,
        })
      );
      return Object.assign(_obj, { [key]: values });
    })
    .value();

  // format positive feedback
  if (positiveFeedbacks?.length > 0) {
    _.chain(positiveFeedbacks)
      .groupBy("name")
      .map((value, key) => {
        let values = {};
        value.map((itm, i) =>
          Object.assign(values, {
            [itm.period]: itm.values[itm.values.length - 1].value,
          })
        );
        // positive feedback response data needs to be formated in order to be return to dashboard as others
        const formatedValues = Object.fromEntries(
          Object.entries(values).map((entry) => [
            entry[0],
            Object.values(entry[1]).reduce((accumulator, value) => {
              return accumulator + value;
            }, 0),
          ])
        );
        return Object.assign(_obj, { [key]: formatedValues });
      })
      .value();

    //Data to show for donut chart
    Object.assign(_obj, {
      monlthyPositiveFeebackByType:
        _getMonlthyPositiveFeebackFormatedForDonutChart(positiveFeedbacks),
    });
  }
  return _obj;
};

const _formatedGraphData = (data) => {
  let _obj = {};
  const groupedData = data.reduce((x, y) => {
    (x[y.name] = x[y.name] || []).push(y);
    return x;
  }, {});
  Object.entries(groupedData).forEach(([key, value]) => {
    let values = [];
    value
      .find((x) => x.period === "day")
      ?.values.forEach((value, key) => {
        values.push({
          name: _formatDate(value.end_time),
          total: value.value,
        });
      });
    return Object.assign(_obj, { [key]: values });
  });

  return _obj;
};

const _formatDate = (date) => {
  const todayDate = new Date(date);
  const day = todayDate.getDate();
  const month = todayDate.getMonth();
  const year = todayDate.getFullYear();

  return `${year}-${month + 1}-${day}`;
};

const _getMonlthyPositiveFeebackFormatedForDonutChart = (date) => {
  let values = [];

  const monthlyPositiveFeedbackByType = date.find((x) => x.period === "days_28")
    .values[0].value;

  Object.entries(monthlyPositiveFeedbackByType).forEach(([key, value]) => {
    values.push({
      name: key.toString(),
      value: value,
    });
  });

  return values;
};

const _mockData = () => {
  return [
    {
      name: "page_impressions_unique",
      period: "day",
      values: [
        {
          value: 4,
          end_time: "2023-04-03T07:00:00+0000",
        },
        {
          value: 1,
          end_time: "2023-04-04T07:00:00+0000",
        },
      ],
      title: "Daily Total Reach",
      description:
        "Daily: The number of people who had any content from your Page or about your Page enter their screen. This includes posts, check-ins, ads, social information from people who interact with your Page and more. (Unique Users)",
      id: "112726521456325/insights/page_impressions_unique/day",
    },
    {
      name: "page_engaged_users",
      period: "day",
      values: [
        {
          value: 0,
          end_time: "2023-04-03T07:00:00+0000",
        },
        {
          value: 0,
          end_time: "2023-04-04T07:00:00+0000",
        },
      ],
      title: "Daily Page Engaged Users",
      description:
        "Daily: The number of people who engaged with your Page. Engagement includes any click or story created. (Unique Users)",
      id: "112726521456325/insights/page_engaged_users/day",
    },
    {
      name: "page_impressions_unique",
      period: "week",
      values: [
        {
          value: 20,
          end_time: "2023-04-03T07:00:00+0000",
        },
        {
          value: 21,
          end_time: "2023-04-04T07:00:00+0000",
        },
      ],
      title: "Weekly Total Reach",
      description:
        "Weekly: The number of people who had any content from your Page or about your Page enter their screen. This includes posts, check-ins, ads, social information from people who interact with your Page and more. (Unique Users)",
      id: "112726521456325/insights/page_impressions_unique/week",
    },
    {
      name: "page_engaged_users",
      period: "week",
      values: [
        {
          value: 1,
          end_time: "2023-04-03T07:00:00+0000",
        },
        {
          value: 0,
          end_time: "2023-04-04T07:00:00+0000",
        },
      ],
      title: "Weekly Page Engaged Users",
      description:
        "Weekly: The number of people who engaged with your Page. Engagement includes any click or story created. (Unique Users)",
      id: "112726521456325/insights/page_engaged_users/week",
    },
    {
      name: "page_impressions_unique",
      period: "days_28",
      values: [
        {
          value: 651,
          end_time: "2023-04-03T07:00:00+0000",
        },
        {
          value: 646,
          end_time: "2023-04-04T07:00:00+0000",
        },
      ],
      title: "28 Days Total Reach",
      description:
        "28 Days: The number of people who had any content from your Page or about your Page enter their screen. This includes posts, check-ins, ads, social information from people who interact with your Page and more. (Unique Users)",
      id: "112726521456325/insights/page_impressions_unique/days_28",
    },
    {
      name: "page_engaged_users",
      period: "days_28",
      values: [
        {
          value: 26,
          end_time: "2023-04-03T07:00:00+0000",
        },
        {
          value: 26,
          end_time: "2023-04-04T07:00:00+0000",
        },
      ],
      title: "28 Days Page Engaged Users",
      description:
        "28 Days: The number of people who engaged with your Page. Engagement includes any click or story created. (Unique Users)",
      id: "112726521456325/insights/page_engaged_users/days_28",
    },
  ];
};

export default useFacebook;
