import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const tempData = [
  {
    name: "2023-04-06",
    total: 1000,
  },
  {
    name: "2023-04-07",
    total: 0,
  },
  {
    name: "2023-04-08",
    total: 9,
  },
  {
    name: "2023-04-09",
    total: 6,
  },
  {
    name: "2023-04-10",
    total: 0,
  },
  {
    name: "2023-04-11",
    total: 9,
  },
  {
    name: "2023-04-12",
    total: 12,
  },
];

export const HSBarChart = ({ data = tempData, dataKey = "total" }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis orientation="right" tickCount={10} />
        <Tooltip />
        <Bar dataKey={dataKey} fill="#1E2875" />
      </BarChart>
    </ResponsiveContainer>
  );
};
