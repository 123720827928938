import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const tempData = [
  {
    name: "Name 1",
    value: 4000,
  },
  {
    name: "Name 2",
    value: 3000,
  },
  {
    name: "Name 3",
    value: 2000,
  },
  {
    name: "Name 4",
    value: 2780,
  },
  {
    name: "Name 5",
    value: 1890,
  },
  {
    name: "Name 6",
    value: 2390,
  },
  {
    name: "Name 7",
    value: 3490,
  },
];

export const HSAreaChart = ({
  data = tempData,
  dataKey = "value",
  nameKey = "name",
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={nameKey} />
        <YAxis orientation="right" tickCount={5} />
        <Tooltip />
        <Area
          type="monotone"
          dataKey={dataKey}
          stroke="#6EBC7F"
          fill="#D1EAD7"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
