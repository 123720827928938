import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "./tabs";
import useApps from "../../hooks/useApps";
import PageList from "./page-list";

export const Client = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apps, setApps] = useState([]);
  const [activeApp, setActiveApp] = useState({});
  const [pages, setPages] = useState([]);

  const _showLoader = (val) => {
    setLoading(val);
    if (val === true) setError(null);
  };

  const { getUserApps, getPages, saveActiveApp, saveActivePage, deletePage } =
    useApps(_showLoader);

  useEffect(() => {
    (async () => {
      const { success, error, apps, activeApp } = await getUserApps();
      if (!success) return setError(error);
      if (!activeApp) setError(error);
      setActiveApp(activeApp);
      setApps(apps);
      // get pages
      await handleTabOnChange();
    })();
    // eslint-disable-next-line
  }, []);

  const _onChangeActiveApp = async (appId) => {
    const { success, updatedApps } = await saveActiveApp(apps, appId);
    if (!success) return setError(error);
    const _activeApp = updatedApps.filter((val, i) => val.is_default)[0];
    setActiveApp(_activeApp);
    setApps(updatedApps);
    await handleTabOnChange();
  };

  const handleTabOnChange = async () => {
    setPages([]);
    if (!activeApp) return;
    const { success, error, hasPages, pages } = await getPages();
    if (!success) return setError(error);
    if (!hasPages) return setError(`There are no pages for ${activeApp}`);
    setPages(pages);
  };

  const _onSaveActivePage = async (page) => {
    const { success, updatedPages } = await saveActivePage(
      pages,
      page.app_id,
      page.page_id
    );
    if (!success) return setError(error);
    setPages(updatedPages);
  };

  const _onDeletePage = async (page) => {
    const { success, updatedPages } = await deletePage(pages, page.id);
    console.log(updatedPages);
    if (!success) return setError(error);
    setPages(updatedPages);
  };
  return loading ? (
    <div>Loading</div>
  ) : (
    apps && apps.length > 0 && (
      <Tabs>
        {apps.map((itm, i) => {
          console.log(itm.app_id, activeApp.app_id);
          return itm.app_id === activeApp.app_id ? (
            <Tab
              onActive={_onChangeActiveApp}
              key={itm.app_id}
              active
              component={
                <PageList
                  pages={pages}
                  onSetActive={_onSaveActivePage}
                  onDelete={_onDeletePage}
                />
              }
            >
              {itm.name}
            </Tab>
          ) : (
            <Tab
              onActive={_onChangeActiveApp}
              key={itm.app_id}
              component={
                <PageList
                  pages={pages}
                  onSetActive={_onSaveActivePage}
                  onDelete={_onDeletePage}
                />
              }
            >
              {itm.name}
            </Tab>
          );
        })}
      </Tabs>
    )
  );
};
export default Client;
