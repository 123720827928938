import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth/context";

import { HSAreaChart, HSBarChart, HSLineChart, HSDonutChart } from "./charts";
import { TfiExport } from "react-icons/tfi";

import Modal from "../shared/modal";
import Loader from "../shared/loader";
import { FBPageTemplate } from "../shared/templates/fbpage.template";
import { SessionExpired } from "../shared/session-expired";
import { toast } from "react-toastify";

import useFacebook from "../../hooks/useFacebook";
import { Tooltip } from "../Tooltip";

export const FacebookDashboard = ({
  hasPages,
  activeApp,
  activePage,
  onSetActivePage,
  savePages,
  setError,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [pages, setPages] = useState([]);
  const [insights, setInsights] = useState({});
  const [posts, setPosts] = useState({});
  const [graphData, setGraphData] = useState({});
  const [followersAndFan, setFollowersAndFan] = useState({});
  const [filterDate, setDateFilter] = useState("Start Date / End Date");

  const { userData } = useContext(AuthContext);
  const {
    fetchInsights,
    fetchPages,
    fetchPostsData,
    fetchFollowersAndFanCount,
    fetchInsightsGraphData,
  } = useFacebook();

  useEffect(() => {
    if (!hasPages) return;
    (async () => {
      await initializeInsights();
      await initializePostsData();
      await initializeFollowesAndFanData();
      await initializeInsightsGraphData();
    })();
    // eslint-disable-next-line
  }, []);

  const initializeInsights = async () => {
    const { isSuccess, newToken, error, data } = await fetchInsights(
      activePage.page_id,
      activePage.page_token
    );
    if (!isSuccess) return setError(error);

    if (newToken)
      await savePages([{ ...activePage, page_token: newToken }], true);

    if (!isSuccess) return setError(error);

    setInsights(data);
  };

  const initializeInsightsGraphData = async () => {
    const { isSuccess, newToken, error, data } = await fetchInsightsGraphData(
      activePage.page_id,
      activePage.page_token
    );
    if (!isSuccess) return setError(error);

    if (newToken)
      await savePages([{ ...activePage, page_token: newToken }], true);

    if (!isSuccess) return setError(error);

    setGraphData(data);
    setDateFilter(
      `${data?.dataTotalForAWeekPerDay?.page_views_total[0].name} / ${
        data?.dataTotalForAWeekPerDay?.page_views_total[
          data?.dataTotalForAWeekPerDay?.page_views_total.length - 1
        ].name
      }`
    );
  };

  const initializePostsData = async () => {
    const { isSuccess, newToken, error, data } = await fetchPostsData(
      activePage.page_id,
      activePage.page_token
    );

    if (!isSuccess) return setError(error);

    if (newToken)
      await savePages([{ ...activePage, page_token: newToken }], true);

    if (!isSuccess) return setError(error);

    setPosts(data);
  };

  const initializeFollowesAndFanData = async () => {
    const { isSuccess, newToken, error, data } =
      await fetchFollowersAndFanCount(
        activePage.page_id,
        activePage.page_token
      );

    if (!isSuccess) return setError(error);

    if (newToken)
      await savePages([{ ...activePage, page_token: newToken }], true);

    if (!isSuccess) return setError(error);

    setFollowersAndFan(data);
  };

  const authenticate = async () => {
    const { success, error, fbPages } = await fetchPages(
      userData.user_id,
      activeApp.app_id
    );
    if (!success) return setError(error);
    setPages(fbPages);
    setShowModal(true);
  };

  const onDateFilter = (e) => {
    setDateFilter(e.value);
  };

  const onModalCloseSave = async (isOK) => {
    if (!isOK) return setShowModal(false);
    else if (!activePage) return toast.warn("Please choose a default page.");
    setShowModal(false);
    const isSuccess = await savePages(pages, true);
    if (isSuccess) await initializeInsights();
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {showModal ? (
        <Modal
          title="Facebook Pages"
          content={
            <FBPageTemplate
              pages={pages}
              activePageId={activePage?.page_id}
              onSetActive={onSetActivePage}
            />
          }
          onModalClose={onModalCloseSave}
        />
      ) : null}
      {!hasPages && (
        <SessionExpired
          message="Please authenticate to get Facebook pages and profiles."
          callbackTitle="Authenticate"
          callback={authenticate}
        />
      )}
      {Object.keys(insights).length &&
      Object.keys(posts).length &&
      Object.keys(followersAndFan).length ? (
        <>
          <div className="flex grow flex-row gap-6 items-stretch">
            {/* <!-- Row 1 Box 1 --> */}
            <div className="w-1/4 bg-white rounded-xl border border-slate-200">
              <div className="px-5 pt-5">
                <h2 className="text-base text-slate-800">
                  <p className="font-normal">Total posts</p>
                </h2>
                <div className="text-3xl font-bold text-slate-800 py-2">
                  {posts?.totalCount}
                </div>
                <div>
                  <span className="text-xs leading-none text-green-400">
                    +{posts?.monlthyTotalCount}{" "}
                  </span>
                  <span className="text-xs leading-none text-gray-500">
                    From the last month
                  </span>
                </div>
              </div>
              {/* <!-- Chart --> */}
              <div className="relative p-4 h-72">
                <HSLineChart
                  data={posts?.groupedByCountPosts}
                  dataKey="count"
                />
              </div>
            </div>

            {/* <!-- Row 1 Box 2 --> */}
            <div className="w-4/6 bg-white rounded-xl border border-slate-200 px-5 pt-5">
              <div className="grid grid-cols-12">
                <div className="col-span-10">
                  <h2 className="text-base text-slate-800">
                    <Tooltip
                      title="The number of times a Page has been viewed by logged-in and
                      logged-out people."
                    >
                      <p className="font-normal"> New platform visitors</p>
                    </Tooltip>
                  </h2>

                  <div className="flex flex-row justify-start mt-8 text-left">
                    <div className="mr-5">
                      <p className="text-sm text-gray-400">Monthly visitors</p>
                      <p className="text-3xl leading-[1] text-slate-800">
                        +{insights.page_views_total.days_28}{" "}
                      </p>
                    </div>
                    <div className="">
                      <p className="text-sm">Daily new visitors</p>
                      <p className="text-3xl leading-[1] text-green-400">
                        +{insights.page_views_total.day}{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-xs col-span-2 block text-right">
                  <div className="xl:w-36 float-right">
                    <input
                      type="text"
                      value={filterDate}
                      onChange={(e) => onDateFilter}
                      style={{ backgroundColor: "#FAF8F7", border: "none" }}
                      className="form-control block w-full px-3 py-1.5 font-semibold text-center text-gray-700 focus:text-gray-700 focus:border-blue-600 focus:outline-none"
                    />
                  </div>
                </div>
              </div>

              {/* <!-- Chart --> */}
              <div className="w-1x2 p-auto h-72 mt-8">
                <HSBarChart
                  data={graphData?.dataTotalForAWeekPerDay?.page_views_total}
                  dataKey="total"
                />
              </div>
            </div>

            {/* <!-- Row 1 Box 3 --> */}
            <div className="w-1/5 bg-white rounded-xl border border-slate-200 border border-slate-200">
              <div className="px-5 pt-5">
                <h2 className="text-base text-slate-800">
                  <Tooltip
                    title=" The number of people who had any content from your Page or about
                your Page enter their screen. This includes posts, stories,
                check-ins, ads, social information from people who interact with
                your Page and more."
                  >
                    <p className="font-normal">Daily impressions of posts</p>
                  </Tooltip>
                </h2>

                <div className="text-4xl font-semibold text-slate-800">
                  {insights?.page_impressions_unique?.day}
                </div>
                <div className="pt-2">
                  <span className="text-xs leading-none text-green-400">
                    +{insights?.page_impressions_unique?.days_28}{" "}
                  </span>
                  <span className="text-xs leading-none text-gray-500">
                    From the last month
                  </span>
                </div>
              </div>
              {/* <!-- Chart --> */}
              <div className="relative p-4 h-72">
                <HSLineChart
                  data={
                    graphData?.dataTotalForAWeekPerDay?.page_impressions_unique
                  }
                  dataKey="total"
                />
              </div>
            </div>
          </div>
          {/* <!-- Row 2 --> */}
          <div className="flex grow flex-row gap-6 py-5 items-stretch">
            {/* <!-- Row 2 Box 1 --> */}
            <div className="w-4/6 bg-white rounded-xl border border-slate-200">
              <div className="p-5">
                <div className="col-span-10">
                  <h2 className="text-base text-slate-800">
                    <Tooltip title="The number of times any content from your Page or about your Page entered a person's screen with social information attached.">
                      <p className="font-normal"> Daily Viral impressions</p>
                    </Tooltip>
                  </h2>
                </div>
              </div>

              {/* <!-- Chart --> */}
              <div className="relative py-4 pl-6 h-72 mt-10">
                <HSAreaChart
                  data={
                    graphData?.dataTotalForAWeekPerDay?.page_impressions_viral
                  }
                  dataKey="total"
                />
                {/* <canvas id="lineChart"></canvas> */}
              </div>
            </div>

            {/* <!-- Row 2 Box 2 --> */}

            <div className="flex flex-col w-1/4 bg-white rounded-xl border border-slate-200 justify-between">
              <div className="px-5 pt-5">
                <h2 className="text-base text-slate-800">
                  <p className="font-normal">Monthly positive feedback</p>
                </h2>
                <span className="text-xs leading-none text-gray-500">
                  Shown by type
                </span>
                {/* <!-- Chart --> */}
                <div className="">
                  <div className="mr-5">
                    <HSDonutChart
                      data={insights?.monlthyPositiveFeebackByType}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/5 bg-white rounded-xl border border-slate-200 border border-slate-200">
              <div className="px-5 pt-5">
                <h2 className="text-base text-slate-800">
                  <Tooltip title=" Daily total post like reactions of the page.">
                    <p className="font-normal">
                      Daily total post "like" reactions
                    </p>
                  </Tooltip>
                </h2>
              </div>
              <div className="relative p-4 h-72">
                <HSLineChart
                  data={
                    graphData?.dataTotalForAWeekPerDay
                      ?.page_actions_post_reactions_like_total
                  }
                  dataKey="total"
                  strokeColor="#FCD34D"
                />
              </div>
            </div>
          </div>
          <div className="flex grow flex-row gap-6 py-5 items-stretch">
            <div className="w-1/4 bg-white rounded-lg border-2 border-slate-100 p-4 sm:p-6 xl:p-8 ">
              <h3 className="text-base">
                <Tooltip title="The number of people who took a positive action (e.g., like, comment, claim, other).">
                  <p className="font-normal text-black">
                    Daily positive feedback
                  </p>
                </Tooltip>
              </h3>
              <span className="text-2xl md:text-4xl lg:text-4xl leading-none text-gray-900">
                {insights.page_positive_feedback_by_type.day}{" "}
              </span>
              <div className="pt-4">
                <span className="text-sm leading-none text-green-400">
                  +{insights.page_positive_feedback_by_type.days_28}{" "}
                </span>
                <span className="text-sm leading-none text-gray-500">
                  From the last month
                </span>
              </div>
            </div>

            <div className="w-1/4 bg-white rounded-lg border-2 border-slate-100 p-4 sm:p-6 xl:p-8 ">
              <h3 className="text-base">
                <Tooltip title="The number of times people took a negative action (e.g., un-liked or hid a post).">
                  <p className="font-normal text-black">
                    Daily negative feedback
                  </p>
                </Tooltip>
              </h3>
              <span className="text-2xl md:text-4xl lg:text-4xl leading-none text-gray-900">
                {insights.page_negative_feedback.day}{" "}
              </span>
              <div className="pt-4">
                <span className="text-sm leading-none text-green-400">
                  +{insights.page_negative_feedback.days_28}{" "}
                </span>
                <span className="text-sm leading-none text-gray-500">
                  From the last month
                </span>
              </div>
            </div>

            <div className="w-1/4 bg-white rounded-lg border-2 border-slate-100 p-4 sm:p-6 xl:p-8 ">
              <h3 className="text-base">
                <Tooltip title="The number of people who engaged with your Page. Engagement includes any click.">
                  <p className="font-normal text-black">Daily Total Reach</p>
                </Tooltip>
              </h3>
              <span className="text-2xl md:text-4xl lg:text-4xl leading-none text-gray-900">
                {insights.page_engaged_users.day}{" "}
              </span>
              <div className="pt-4">
                <span className="text-sm leading-none text-green-400">
                  +{insights.page_engaged_users.days_28}{" "}
                </span>
                <span className="text-sm leading-none text-gray-500">
                  From the last month
                </span>
              </div>
            </div>

            <div className="w-1/4 bg-white rounded-lg border-2 border-slate-100 p-4 sm:p-6 xl:p-8 ">
              <h3 className="text-base">
                <p className="font-normal text-black">Total followers</p>
              </h3>
              <span className="text-2xl md:text-4xl lg:text-4xl leading-none text-gray-900">
                {followersAndFan?.data?.followers_count}
              </span>
              <div className="pt-4">
                <span className="text-sm leading-none text-green-400">
                  +{followersAndFan?.data?.fan_count}{" "}
                </span>
                <span className="text-sm leading-none text-gray-500">
                  People like this page
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
