import React, { useState } from "react";
import { Error } from "../shared/error";
import { ConfirmDialog } from "../shared/confirm-dialog";

export const PageList = ({ pages, onSetActive, onDelete }) => {
  const [active, setActive] = useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [page, setPage] = useState(false);

  const handleDelete = (page) => {
    setPage(page);
    setConfirm(true);
  };
  const handleConfirmOnClosed = (isOk) => {
    setConfirm(false);
    if (isOk) onDelete(page);
  };

  return (
    <div className="flex flex-col justify-center items-center p-2">
      {confirm && (
        <ConfirmDialog
          isOpen={confirm}
          title="Delete page"
          content="Do you want to delete this page?"
          onClose={handleConfirmOnClosed}
        />
      )}
      {!pages || pages.length === 0 ? (
        <Error
          title="Empty resultset"
          error={{ desc: "There are no pages." }}
        />
      ) : (
        <ul className="list-none w-6/12 divide-y divide-gray-200 dark:divide-gray-700">
          {pages && pages.map((page, i) => {
            return (
              <li key={i} className="py-2">
                <div className="flex items-center space-x-4">
                  <div className="flex flex-wrap justify-center background-gray-400">
                    <img
                      className="w-9 h-9"
                      src={page.page_picture}
                      alt="..."
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <h6 className="text-sm font-medium text-gray-900 truncate dark:text-white m-0">
                      {page.page_name}
                    </h6>
                    <span className="text-sm text-gray-500 truncate dark:text-gray-400">
                      {page.page_id}
                    </span>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    <button
                      className={`w-28 ${
                        page.default && "cursor-not-allowed"
                      } bg-slate-400 text-white active:bg-slate-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                      type="button"
                      disabled={page.default}
                      onClick={(_) => {
                        page.default = true;
                        setActive(true);
                        onSetActive(page);
                      }}
                    >
                      {page.default ? "Active" : "Set Active"}
                    </button>
                    <button
                      className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => handleDelete(page)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default PageList;
