import React from "react";
import {
  LineChart,
  Line,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const tempData = [
  {
    name: "5PM",
    pv: 2,
  },
  {
    name: "6PM",
    pv: 77,
  },
  {
    name: "7PM",
    pv: 5,
  },
  {
    name: "8PM",
    pv: 44,
  },
  {
    name: "9PM",
    pv: 12,
  },
];

export const HSLineChart = ({
  data = tempData,
  dataKey = "pv",
  strokeColor = "#8884d8",
}) => {
  const slicedData = data?.slice(-7);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={730}
        height={250}
        data={slicedData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        {/* TODO: Fix label of tooltip*/}
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey={dataKey}
          stroke={strokeColor}
          strokeWidth={3}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
